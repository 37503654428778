@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500&display=swap");
@import url("Fonts/Gtwalsheimpro/stylesheet.css");
*,
html {
  overflow: hidden;
  letter-spacing: 1.1px;
}
#root {
  overflow: hidden;
}
.react-reveal {
  overflow: visible;
}
.App {
  height: 100vh;
  background: rgb(202, 103, 255);
  background: linear-gradient(
    60deg,
    rgba(202, 103, 255, 1) 0%,
    rgba(124, 20, 248, 1) 100%
  );
  transition: top 1s ease-in-out;
  font-family: "GT Walsheim Pro", cursive;
  font-weight: 200;
  position: relative;
}

.fade {
  position: relative;
  width: 100%;
  min-height: 60vh;
  top: -25px;
  background-image: linear-gradient(0deg, transparent, black 75%);
}

.resume-btn {
  background:  #c41c1c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 25px;
  padding: 0.8% 2% !important;
  color: black !important;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
}
.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  width: 100%;
  padding: 2% 5% 0% 5%;
  z-index: 15;
}

.navbar-btn-txt {
  margin: 0% 5%;
  font-weight: 400;
}

.bg:after {
  content: " ";
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: url("http://assets.iceable.com/img/noise-transparent.png") repeat
    0 0;
  background-repeat: repeat;
  animation: bg-animation 0.2s infinite;
  opacity: 1;
}

.right-menu {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 18px;
}
#section07 {
  background: url(https://picsum.photos/1200/800?image=675) center center /
    cover no-repeat;
}
.left-menu {
  display: flex;
  align-items: center;
}
.header {
  height: 100px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: end;
}

.title {
  font-size: 26px;
  font-family: "GT Walsheim Pro", sans-serif !important;
  font-weight: 100;
  font-style: normal;
  margin-bottom: 0 !important;
}

.btn {
  background-color: transparent !important;
  color: white;
  border: none;
  margin-right: 80px;
  font-size: 20px;
}

.module-border-wrap {
  position: relative;
  background: linear-gradient( to right, #ff4b2b, #c41c1c );
  padding: 3px;
  border-radius: 10px;
  margin: 2px 1%;
}

.module:hover {
  background: rgba(0, 0, 0, 0);
}

.module {
  background: #2f2f2f;
  color: white;
  padding: 0.7rem 3rem;
  border-radius: 10px;
  transition: background-color ease 0.5s;

}
.css-10botns-MuiInputBase-input-MuiFilledInput-input,
.css-1gzyby-MuiInputBase-root-MuiFilledInput-root {
  background-color: #9f9f9f45 !important;
  color: white;
}
.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(140 140 140) !important;
}
.css-19mk8g1-MuiInputBase-root-MuiFilledInput-root::after,
.css-1gzyby-MuiInputBase-root-MuiFilledInput-root:after {
  border-color:  #c41c1c !important;
  border-bottom: 3px solid  #c41c1c !important;
}
.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  color: white;
}
.MuiInputLabel-root,
.css-7209ej-MuiInputBase-input-MuiFilledInput-input {
  color: white !important;
}

.title-scroll {
  position: absolute;
  left: 50%;
  bottom: 34%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 100;
}
.titleBeforeMovement {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 12;
}
.MuiFormControl-root {
  padding: 1% !important;
  width: 100%;
}
.MuiFilledInput-root, .MuiTextField-root {
  border-radius: 10px !important;
  overflow: hidden;
}
.button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  font-size: 1rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 204, 255, 0);
  border-radius: 10rem;
  z-index: -2;
}
.button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background:  #c41c1c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}
.button-red {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: white 2px solid;
}
.button-red:hover {
  color: white !important;
}
.button-red:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 204, 255, 0);
  border-radius: 10rem;
  z-index: -2;
}
.projects-list {
  width: 100%;
  padding: 0 5%;
  display: flex;
  flex-direction: row;
  flex: 4;
  flex-wrap: wrap;
}

.project-container {
  width: 28%;
  background-color: #2f2f2f;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  font-family: "GT Walsheim Pro", cursive !important;
  font-weight: 100;
  border-radius: 20px;
  border: 1px solid rgba(180, 180, 180, 0.14);
}
.circle-1 {
  min-width: 900px;
  min-height: 900px;
  width: 65vw;
  height: 65vw;
  background-color: #111111;
  border-radius: 50%;
  transition: top 1s ease-in-out;
  position: absolute;
  top: 15%;
  left: 2%;
}
.nav-link {
  color: white !important;
  transition: 1s ease-in-out;
  
}
.nav-link:hover{
  color:  #c41c1c !important;
  
}
.button-red-resume:hover {
  color: white !important;
}
.project-container h1,
.project-container h6 {
  font-weight: 100 !important;
}
.darken,
.darkener {
  display: flex;
  width: 100%;
  height: 100%;
}
.btns-row {
  display: flex;
  height: 15%;
}
.project-name-date {
  justify-self: flex-end;
  position: absolute;
  bottom: 15%;
  padding: 0 2%;
  width: 100%;
}
.project-name-date h1 {
  overflow: visible;
}
.btn-git,
.btn-enter {
  flex: 1;
  border: 0px solid;
  font-weight: 200;
  height: 100%;
  color: white;
  font-family: "GT Walsheim Pro", cursive !important;
}
.tech-stack {
  display: flex;
  flex-direction: row;
}
.tech-bubble {
  padding: 0.5% 1%;
  border: 1px solid rgb(124, 124, 124);
  border-radius: 12px;
  margin: 1%;
  font-size: 13px;
}
.btn-git {
  background-color: #2f2f2f;
}
.btn-enter {
  background:  #c41c1c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.project-image-head {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
}
.darken {
  background: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.65) 30%,
    rgba(223, 181, 75, 0) 100%
  );
}
.button-red:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background:  #c41c1c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.button-red:hover:before {
  width: 100%;
}

.button-red-box {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.button-red-box:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 204, 255, 0);
  z-index: -2;
}
.button-red-box:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #ff7070;
  transition: all 0.3s;
  z-index: -1;
}

.button-red-box:hover:before {
  width: 100%;
}

.button-git-box {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.button-git-box:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 204, 255, 0);
  z-index: -2;
}
.button-git-box:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #747474;
  transition: all 0.3s;
  z-index: -1;
}

.button-git-box:hover:before {
  width: 100%;
}

.button-red-resume {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  font-size: 18px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.button-red-resume:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 204, 255, 0);
  border-radius: 10rem;
  z-index: -2;
}
.button-red-resume:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #ff8c8c;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.button-red-resume:hover:before {
  width: 100%;
}
.button:hover {
  color: #fff;
}
.button:hover:before {
  width: 100%;
}

.contact-container {
  display: flex;
  flex-direction: row;
  background-color: #2f2f2f;
  width: 85%;
  height: 95%;
  justify-content: center;
  border-radius: 20px;
}
.MuiPaper-root .btns-row {
  height: 7%;
}
.contact-col {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 2%;
  justify-content: center;
  position: relative;
}
.contact-bubble {
  width: 65px;
  height: 65px;
  background: linear-gradient( to right, #ff4b2b, #c41c1c );
  color: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3%;
  font-weight: 100;
  font-size: 30px;
}
.bubble-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 100;
  white-space: nowrap;
  color: white !important;
  text-decoration: none;
}
.bubble-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3%;
}
.contact-submit-btn {
  width: 200px;
  padding: 2%;
  margin: 2% 0;
  background-color: transparent;
  border: 3px solid  #c41c1c;
  color:  #c41c1c;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 2px;
  align-self: flex-end;
  font-family: "GT Walsheim Pro";
}
.hover-animate-static > span {
  position: relative;
  display: inline-block;
  animation: flip-reverse-static 1s;
  transition-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  margin-bottom: 0 !important;
}
.hover-animate-static > span:hover {
  position: relative;
  -webkit-animation: flip-static 1s;
  animation: flip-static 1s;
  transition-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.hover-animate > span,
.hover-animate > b > span {
  display: inline-block;
  animation: flip-reverse 1s;
  transition-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  overflow: visible;
  color: white;
}
.hover-animate > span:hover {
  -webkit-animation: flip 1s;
  animation: flip 1s;
  transition-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes flip {
  25% {
    color:  #ff4b2b;
  }
  100% {
    color:  #ff4b2b;
    transform: rotateY(180deg);
  }
}
@keyframes flip-reverse {
  0% {
    transform: rotateY(180deg);
    color:  #ff4b2b;
  }
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes flip-static {
  30% {
    color:  #ff4b2b;
  }
  100% {
    color:  #ff4b2b;
  }
}
@keyframes flip-reverse-static {
  0% {
    color:  #ff4b2b;
  }
  100% {
    color: #2f2f2f;
  }
}
.hover-animate-main > span {
  display: inline-block;
  animation: flip-reverse-main 1s;
  transition-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  overflow: visible;
}
.hover-animate-main > span:hover {
  -webkit-animation: flip-main 1s;
  animation: flip-main 1s;
  transition-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes flip-main {
  25% {
    background: -webkit-linear-gradient(to right, #dd2476, #ff512f);
    background: linear-gradient(to right, #dd2476, #ff512f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: skewY(30deg);
  }
  100% {
    background: -webkit-linear-gradient(to right, #dd2476, #ff512f);
    background: linear-gradient(to right, #dd2476, #ff512f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotateY(180deg);
  }
}
@keyframes flip-reverse-main {
  0% {
    transform: rotateY(180deg);
    color:  #c41c1c;
  }
  100% {
    transform: rotateY(0deg);
  }
}
.project-img-mobile {
  object-fit: cover;
}
.hover-animate-bubble {
  animation: flip-bubble-reverse 0.8s;
  transition-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.hover-animate-bubble:hover {
  position: relative;
  -webkit-animation: flip-bubble 0.8s;
  animation: flip-bubble 0.8s;
  transition-timing-function: ease-in-out;
  font-weight: bold;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  color: white !important;
}
.MuiCircularProgress-colorPrimary {
  color:  #c41c1c !important;
}
@keyframes flip-bubble {
  30% {
    background-color: white;
    color: black;
  }
  100% {
    background-color: white;
    color: black;
    transform: rotateY(180deg);
  }
}
@keyframes flip-bubble-reverse {
  0% {
    background-color: white;
    color: black;
    transform: rotateY(0deg);
  }
  100% {
    background-color:  #c41c1c;
    color: white;
    transform: rotateY(-180deg);
  }
}

@keyframes custom-animation-white {
  0% {
    transform: translate3d(0px, -110px, 0px);
    animation-timing-function: ease-in-out;
    opacity: 0;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in-out;
    opacity: 1;
    color: white;
  }
}

@keyframes custom-animation-gradient {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes custom-animation-gradient1 {
  0% {
    color: #ffffff;
  }

  20% {
    color: #ffd1d1;
  }

  40% {
    color: #ff7171;
  }

  100% {
    color: #ff4040;
  }
}

@keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#cf4a img:nth-of-type(1) {
  animation-delay: 6s;
}

#cf4a img:nth-of-type(2) {
  animation-delay: 4s;
}

#cf4a img:nth-of-type(3) {
  animation-delay: 2s;
}

#cf4a img:nth-of-type(4) {
  animation-delay: 0;
}

#cf3 img.top {
  animation-name: cf3FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-direction: alternate;
}

@keyframes animation-horizontal {
  0% {
    transform: translate(0px, 0px);
    animation-timing-function: ease-in-out;
    color: white;
  }

  100% {
    transform: translate(-100%, 0px);
    animation-timing-function: ease-in-out;
  }
}

@keyframes animation-horizontal-screenshot {
  0% {
    transform: translate(5%, 0px);
    animation-timing-function: ease-out;
    color: white;
  }

  100% {
    transform: translate(-5%, 0px);
    animation-timing-function: ease-out;
  }
}

/* .delay-1 {
  animation-delay: 1s !important;
} */
/* 
.delay-2 {
  animation-delay: 1.4s !important;
} */

.custom-animation-white {
  animation: 1.5s ease-in-out 0s 1 normal forwards running
    custom-animation-white;
}

.custom-animation-gradient {
  color:  #c41c1c;
  animation: 1.5s ease-in 0s 1 normal forwards running custom-animation-gradient;
}

.custom-animation-gradient1 {
  animation: 2s ease-in-out 0s 1 normal forwards running
    custom-animation-gradient1;
}

.infinite {
  background: #00f260;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #dd2476, #ff512f);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #dd2476, #ff512f);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: 2.8s ease-in 0s 1 normal forwards running
    custom-animation-gradient-broken;
  animation-iteration-count: infinite;
}

.animation-horizontal {
  animation: 1s ease-in-out 0s 1 normal forwards running animation-horizontal;
}
.iAHkiA {
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.content {
  width: 100%;
  height: 90%;

  overflow: hidden;
  /* top: 0; */
}

.ieWDks {
  background-repeat: no-repeat;
  background-size: contain !important;
  border-radius: 5px;
  max-width: 95%;
}

.react-reveal {
  height: 100%;
  width: 100%;
  position: absolute;
}
.home-vector {
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 25;
  width: 40%;
  z-index: 30;
  object-fit: contain;
}
h1 {
  line-height: 0.8 !important;
}
.vertical-h1 {
  display: flex;
  flex-direction: column;
}
.content-title {
  font-size: 11vw;
  font-weight: 400;
  font-family: "GT Walsheim Pro Condensed", sans-serif;
  font-weight: 700;
  display: "flex";
  justify-content: "flex-start";
  margin-left: 5%;
}
.about-subtitle {
  color:  #ff4b2b;
}
.about-cropper {
  border-radius: 50%;
  align-self: flex-start;
  width: 500px;
  height: 500px;
  max-width: 550px;
  object-fit: contain;
  margin-top: 4.5%;
}

.myfilter {
  position: relative;
  margin: 0 10%;
}

.myfilter:after {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background: linear-gradient(to right,  #c41c1c00 0%,  #c41c1c7a 100%);
  mix-blend-mode: color;
}
.about-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-left: 10%;
  margin-top: 5%;
}
.content-title-project {
  font-size: 45px;
  overflow: visible;
  margin: 5% 0%;
  font-weight: 200;
}

.chevron {
  position: absolute;
  width: 2rem;
  height: 0.3rem;
  opacity: 0;
  transform: scale(0.2);
  -webkit-animation: move-chevron 3s ease-out infinite;
  animation: move-chevron 3s ease-out infinite;
  overflow: visible;
}

.chevron:first-child {
  -webkit-animation: move-chevron 3s ease-out 1s infinite;
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  -webkit-animation: move-chevron 3s ease-out 2s infinite;
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #ffffff9d;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@-webkit-keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}
.chevron-container {
  width: 50px;
  position: absolute;
  top: 80%;
  right: 0.5%;
  height: 200px;
}
.title1 {
  font-size: 130px;
  font-family: "GT Walsheim Pro Ultra", sans-serif;
  top: 15%;
  z-index: 15;
}
.project-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5%;
}
.alon-title {
  font-size: 90px;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  animation: 1.5s ease-in-out 0s 1 normal forwards running moveTitle;
}
.fade-animate {
  animation: 1.5s linear 0s 1 normal forwards running fade-animate;
}
@keyframes moveTitle {
  0% {
    left: 50%;
    top: 15%;
  }
  40% {
    opacity: 1;
  }
  80% {
    left: 100px;
    top: -60px;
  }

  100% {
    left: 100px;
    top: -60px;
    opacity: 0;
    font-size: 35px;
  }
}
@keyframes fade-animate {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.content-title-visit {
  font-size: 1vw;
  background: #ffffff;
  border-radius: 50px;
  font-family: "GT Walsheim Pro", cursive;
  color: #111111;
  width: 100%;
  white-space: nowrap;
  height: 55px;
  padding: 2%;
  letter-spacing: 0.1rem;
  font-weight: 400;
}
.content-title-visit-git {
  font-size: 1vw;
  background: transparent;
  border: 2px solid white;
  color: white !important;
  border-radius: 50px;
  font-family: "GT Walsheim Pro", cursive;
  color: #111111;
  font-weight: 300;
  width: 100%;
  white-space: nowrap;
  height: 55px;
  padding: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1rem;
}
.github-btn-icon {
  width: 1.9em;
  margin-right: 2%;
}
.success {
  background: -webkit-linear-gradient(
    to right,
    #e6c7ff00,
     #c41c1cc0,
     #c41c1cc0,
    #e6c7ff00
  );
  background: linear-gradient(
    to right,
    #e6c7ff00,
     #c41c1cc0,
     #c41c1cc0,
    #e6c7ff00
  );
  padding: 3%;
  color: white;
  font-weight: 300;
  width: 100%;
  text-align: center;
}

.content-title-2 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 100px;
  opacity: 0;
}

.trail {
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background: teal;
}

.full-stack {
  z-index: 1;
}

.developer {
  z-index: 1;
}

.footer {
  position: absolute;
  bottom: -5%;
  left: 50%;
  color: white;
  font-size: 120px;
  font-weight: bolder;
  transform: translate(-50%, -20%);
  font-family: "Bebas Neue", "Courier New", Courier, monospace;
  z-index: 1;
}

.horizontal-line-scope,
.horizontal-line {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.swiper {
  width: 100%;
  height: 100%;
}
.MuiDialog-paper {
  min-width: 800px !important;
  height: 900px;
  background: rgba(180, 180, 180, 0.35) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(180, 180, 180, 0.14);
  color: rgba(255, 255, 255, 1) !important;
  border-radius: 20px !important;
}
.carousel-item img {
  max-height: 400px;
  object-fit: contain;
  border-radius: 20px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.horizontal-line-scope {
  background:  #c41c1c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff4b2b,
     #c41c1c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.css-1wnsr1i {
  border-radius: 20px;
  background-color: #141414 !important;
  color: white;
  height: 90%;
  min-width: 50%;
}
.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 75%) !important;
}
.content-body-project {
  display: flex;
  flex-direction: row !important;
  flex: auto;
  flex-flow: column;
  height: 100%;
}

.content-body {
  display: flex;
  flex-direction: row;
  position: relative;
  font-family: "GT Walsheim Pro", sans-serif;
  width: 100%;
  height: 85%;
  color: white;
  overflow: visible;
  padding-top: 2%;
  flex-wrap: wrap;
}

.screenshot {
  display: inline;
  margin: 0 auto;
  animation: 10s ease-in 0s 1 normal forwards running
    animation-horizontal-screenshot;
  width: 100%;
  object-fit: contain;
}

.image-cropper {
  width: 80%;
  overflow: hidden;
  height: 75%;
  object-fit: contain;
  justify-content: center;
  display: flex;
  z-index: 0;
  margin-top: 1%;
}

.footer-p {
  margin: 0 !important;
}

.linear-wipe {
  text-align: center;
  background: white;

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#FAFF89", endColorstr="#EA72AD", GradientType=1);
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.project-info {
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1% 0 !important;

  position: relative;
  width: 96.5%;
  height: 86px !important;
}
.css-og3m0y::after,
.css-17spqnz::after {
  border-bottom: 2px solid rgb(196 28 28) !important;
}
.tech-logo {
  width: 80% !important;
  object-fit: contain;
  max-height: 60px;
  z-index: 5;
  max-height: 60px;
}
.tech-logo:hover {
  transform: scale(1.3);
  animation-fill-mode: both;
}

.fadeInUp,
.fadeInDown {
  z-index: 2;
}

.tech-col {
  width: 15% !important;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  position: relative;
  overflow: visible;
  justify-content: flex-end;
  z-index: 5;
}
.btns-row button {
  white-space: nowrap;
}
.description {
  font-size: 1.0rem;
  font-weight: 100;
  width: 100%;
  line-height: 1.2;
  margin-top: 0px !important;
  top: 50%;
  line-height: 1.4;
}

.project-modal-description-container {
  position: relative;
  height: 35%;
}

.stack-title {
  white-space: nowrap;
  text-align: center;
  font-weight: 300;
  opacity: 0.6;
  margin: 0 !important;
  font-size: 0.7vw;
  line-height: 16px;
}
.social-links {
  position: absolute;
  left: 0;
  top: 15%;
  background-color: #2f2f2f;
  height: 150px;
  font-size: 30px;
  color: rgb(182, 182, 182);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3%;
  z-index: 20;
  border-radius: 0 10px 10px 0px;
}
.about-description {
  font-size: "17px";
  font-family: "GT Walsheim Pro";
  font-weight: 200;
  width: 35%;
  margin-left: 8%;
}
.social-links a {
  color: rgb(255, 255, 255);
}
.social-links a:hover {
  color:  #ff4b2b;
}

@media only screen and (max-width: 980px) {
  *,
  html {
    overflow: inherit;
  }
  .about-description {
    margin: 2% 0%;
  }
  .contact-bubble {
    letter-spacing: 0.5px;
    font-size: 2em;
  }
  .bubble-col {
    font-size: 2.5vw;
    letter-spacing: 0.5px;
  }
  .react-reveal {
    height: 100%;
    width: unset !important;
    position: unset;
  }
  .tech-col {
    max-width: 20% !important;
    flex-shrink: unset !important;
    position: unset !important;
    padding: 0;
    height: 80px;
  }
  [class^="col"] {
    width: 100% !important;
  }
  .MuiDialog-paper {
    margin: 12px !important;
  }
  .circle-1 {
    left: -450px;
    top: 16%;
    height: 800px;
    width: 1000px;
  }
  .btns-row button {
    font-size: 14px;
  }
  .project-buttons {
    flex-direction: column;
    padding: 5%;
  }
  .image-cropper {
    width: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: unset !important;
    object-fit: contain;
    justify-content: flex-end;
    display: flex;
    z-index: 0;
    align-items: flex-start;
    height: unset;
    right: 3%;
    top: 0;
  }
  .resume-btn {
    margin: auto;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "GT Walsheim Pro", cursive !important;
    font-size: 1em !important;
    min-width: 100%;
    font-weight: 400;
    padding: 0 5% !important;
  }
  .chevron-container {
    width: 34px;
    top: 75vh;
    height: 100px;
    z-index: 15;
  }
  .MuiDialogContent-root {
    overflow-y: auto ;
  }
  .about-title{
    display: flex;
    flex-direction: row;
    width: 100% !important;
  }
  .about-title .col {
    display: flex;
    justify-content: flex-start;

  }
  .about-cropper {
    width: 140px;
    height: 140px;
    position: inherit;
    align-self: center;
    margin: 0 2% 0 0;
  }
  .MuiDialog-paper {
    min-width: unset !important;
    width: 100%;
  }
  .project-modal-description-container {
    height: 60%;
    overflow: hidden;
    position: relative;
  }
  .description {
    top: 30%;

    width: 100%;
    position: absolute;
  }
  .project-container {
    width: 100%;
    margin: 2% 0; 
    overflow: hidden;
    height: 420px;
  }
  .screenshot {
    width: 800px;
    object-fit: cover;
  }
  .titleBeforeMovement {
    margin: auto;
  }
  .content-title {
    margin: 10% 0;
    font-size: 4.7rem;
    text-align: left;
    margin-left: 5px;
    line-height: 1.0 !important;
  }
  .scroll-text {
    color: rgba(255, 255, 255, 0.9);
    position: absolute;
    bottom: 22%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tech-logo {
    max-width: 60px;
  }
  .social-links {
    top: 2%;
    right: 0;
    left: unset;
    border-radius: 10px 0px 0 10px;
    padding: 1.5%;
  }
  .pos-mobile {
    font-size: 4.5vw;
  }
  .project-info {
    width: 100% !important;
    padding: 2%;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap !important;
    --bs-gutter-x: 0 !important;
    justify-content: center;
  }
  .stack-title {
    font-size: 10px !important;
  }
  .content-body {
    margin: 5% 0 ;
  }
  .content-title-visit,
  .content-title-visit-git {
    font-size: 1em;
    letter-spacing: 0.05rem;
    margin: 2% 0;
    width: 100% !important;
  }
  h5 {
    font-size: 1.05rem !important;  
  }
  .about-subtitle {
    margin: 0 5px 5px 0;
    color:  #ff4b2b;
    font-size: 19px;
  }
  .content-body-project {
    width: 100%;
    flex-direction: column !important;
  }
  .col-4 {
    margin: 0 !important;
  }
  .content-title-project {
    font-size: 35px;
  }

  .ieWDks {
    max-width: 100%;
  }
  .content-body-home {
    display: flex;
    flex-direction: column;
    font-family: "GT Walsheim Pro Ultra", sans-serif;
    width: 100%;
    height: 100%;
    color: white;
    min-height: 85vh;
    padding: 0 5%;
    margin-top: 0 !important;
    margin-bottom: 28%;
  }
  .title1 {
    font-size: 90px;
    font-family: "GT Walsheim Pro Ultra", cursive !important;
    position: absolute;
  }
  .App {
    height: unset;
  }
  .contact-submit-btn {
    margin: 5% 0;
  }
  .contact-container {
    padding-top: 10%;
    width: 100%;
    flex-direction: column;
  }
  .contact-col {
    width: 100%;
    margin: 0;
    padding: 0 5%;
  }
  .pos-mobile {
    position: sticky;
  }
  .sticky-top {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      #5000a9 60%
    );
  }
  @keyframes moveTitle {
    0% {
      top: 1.5%;
    }

    100% {
      top: 1.5%;
      font-size: 0px;
      opacity: 0;
    }
  }
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 980px) 
  and (orientation: landscape) { 
    .content-title {
      margin: 0 0 !important;
    }
    .home-vector {
      max-width: 50% !important;
    }
    .content-body-home {
      flex-direction: row !important;
    }
    .content-body {
      margin: 0% 0 !important;
    }
    .contact-container {
      padding: 0 0 !important;
    }
    .titleBeforeMovement {
      min-width: 100%;
    }
}